import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";

const WorkInProgress = () => {
  return (
    <div className="home-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100vh" }}>
      <div className="home-banner-container" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="home-text-section" >
          <h1 className="primary-heading">Hello, नमस्ते 🙏 </h1>
          <p className="primary-text">
            PM SHRI GGIC HALDWANI's website is under construction!  Please check back soon.
            <br />
            <br />
            पीएम श्री जीजीआईसी हल्द्वानी की वेबसाइट निर्माणाधीन है! कृपया जल्द ही वापस आएं।
          </p>
        </div>
        <div className="home-image-section">
          <Player
            src="https://lottie.host/991cf80a-be27-440b-abce-343a00bab492/vBrtsxMmtU.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "300px" }}
            loop
            controls
            autoplay
          />
        </div>
      </div>
    </div>
  );
};

export default WorkInProgress;
